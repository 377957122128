export const RemoveDuplicates = (
  array: (string | undefined)[]
): (string | undefined)[] => {
  const unique: (string | undefined)[] = [];
  array.forEach(element => {
    if (!unique.includes(element)) {
      unique.push(element);
    }
  });
  return unique;
};
