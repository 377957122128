import { gql, TypedDocumentNode } from 'urql';
import {
  IDriverForm,
  IFilterShippingCompanies as IFilterShippingCompanies,
  IGetCadences,
  IGetContractItemsOutput,
  IGetContractItemWhere,
  IGetPreSchedule,
  IGetTruckLoad,
  IGetTruckLoadsResponse,
  IGetTruckLoadWhere,
  IGetVehicles,
  IPreScheduleInput,
  ITruckLoadInput,
  IUpdateTruckLoadInput,
  IUpdateTruckLoadWithTruckLoadItemsInput,
} from '../interfaces/TruckLoad';
import { INewShippingCompany } from '../interfaces/Company/Company';

const TruckLoadQueries = {
  getVehicles: (): TypedDocumentNode<
    { getVehicles: IGetVehicles[] },
    {
      where: {
        organization_id: string;
        take_common_vehicles: boolean;
      };
    }
  > => gql`
    query ListVehicles($where: GetVehicleWhereInput!) {
      getVehicles: ListVehicles(where: $where) {
        id
        vehicleModel: vehicle_model
        bagsCapacity: bags_capacity
        weightCapacity: weight_capacity
      }
    }
  `,

  getCadences: (): TypedDocumentNode<
    { getCadences: IGetCadences[] },
    {
      where: {
        seed_type: string;
        pick_up_location: string;
        // unity_weight?: number;
        unity: string;
      };
    }
  > => gql`
    query ListCadences($where: ListCadencesInput!) {
      getCadences: ListCadences(where: $where) {
        id
        availableUnits: available_units
        startTime: start_time
        endTime: end_time
        whiteSeedOnly: white_seed_only
      }
    }
  `,

  createPreSchedule: (): TypedDocumentNode<
    {
      createPreSchedule: IGetPreSchedule;
    },
    {
      input: IPreScheduleInput;
    }
  > => gql`
    mutation createPreSchedule($input: PreScheduleUncheckedCreateInput!) {
      createPreSchedule: CreatePreSchedule(input: $input) {
        id
        cadence {
          rule {
            capacityPerCompany: capacity_per_company
          }
        }
        createdBy: created_by
        startedAt: started_at
        expiresIn: expires_in
        unitsAmount: units_amount
        pickUpLocation: pick_up_location {
          organizationId: organization_id
        }
      }
    }
  `,

  cancelPreSchedule: (): TypedDocumentNode<
    {
      cancelPreSchedule: { success: boolean };
    },
    {
      where: {
        id: string;
      };
    }
  > => gql`
    mutation CancelPreSchedule($where: PreScheduleWhereUniqueInput!) {
      cancelPreSchedule: CancelPreSchedule(where: $where) {
        success
      }
    }
  `,

  getContractItems: (): TypedDocumentNode<
    { getContractItems: IGetContractItemsOutput },
    { where: IGetContractItemWhere }
  > => gql`
    query ListContractItems($where: ListContractItemsInput!) {
      getContractItems: ListContractItems(where: $where) {
        customerCapacity: customer_capacity {
          initialCapacity: initial_capacity
          remaingAmount: remaining_amount
        }
        contractItems: contract_items {
          id
          seedType: seed_type_id
          sieve
          category
          packing
          tsi
          estimatedWeight: estimated_weight
          totalAmount: full_amount
          remainingAmount: remaining_amount
          cultivation
          referenceNumber: reference_number
          customerCapacity: customer_capacity {
            initialCapacity: initial_capacity
            remainingAmount: remaining_amount
          }
          contract {
            contractDate: contract_date
            referenceNumber: reference_number
            farm {
              name
              stateRegistration: state_registration
              customerId: customer_id
              customer {
                name
                tradeName: trade_name
              }
            }
          }
        }
      }
    }
  `,

  getOrganizationTruckLoads: (): TypedDocumentNode<
    { getTruckLoads: IGetTruckLoadsResponse[] },
    { where: IGetTruckLoadWhere }
  > => gql`
    query GetTruckLoads($where: GetTruckLoadsInput!) {
      getTruckLoads: GetTruckLoads(where: $where) {
        id
        loadedAmount: loaded_amount
        status
        name
        expiresAt: expires_at
        createdAt: created_at
        loadingOrder: loading_order {
          loadingOrder: loading_order
        }
        pickUpLocation: pick_up_location {
          title
        }
        driver {
          name
        }
        cadence {
          startTime: start_time
        }
        vehicle {
          vehicleModel: vehicle_model
        }
        truckLoadItems: truck_load_items {
          contractItem: contract_item {
            contract {
              farm {
                name
              }
            }
          }
        }
      }
    }
  `,

  getShippingCompanies: (): TypedDocumentNode<{
    getShippingCompanies: INewShippingCompany[];
  }> => gql`
    query listShippingCompanies {
      getShippingCompanies: ListShippingCompanies {
        id
        companyName: company_name
        documentNumberCnpj: document_number_cnpj
      }
    }
  `,

  getCustomerTruckLoads: (): TypedDocumentNode<
    { getTruckLoads: IGetTruckLoadsResponse[] },
    {
      where: Record<string, never>;
    }
  > => gql`
    query GetTruckLoads($where: GetTruckLoadsInput!) {
      getTruckLoads: GetTruckLoads(where: $where) {
        id
        loadedAmount: loaded_amount
        status
        name
        expiresAt: expires_at
        createdAt: created_at
        loadingOrder: loading_order {
          loadingOrder: loading_order
        }
        pickUpLocation: pick_up_location {
          title
        }
        driver {
          name
        }
        cadence {
          startTime: start_time
        }
        vehicle {
          vehicleModel: vehicle_model
        }
        truckLoadItems: truck_load_items {
          contractItem: contract_item {
            contract {
              farm {
                name
              }
            }
          }
        }
      }
    }
  `,

  createTruckLoad: (): TypedDocumentNode<
    {
      formTruckLoad: { id: string };
    },
    {
      input: ITruckLoadInput;
    }
  > => gql`
    mutation formTruckLoad($input: TruckLoadCreateInput!) {
      formTruckLoad: FormTruckLoad(input: $input) {
        id
      }
    }
  `,

  getTruckLoad: (): TypedDocumentNode<
    { getTruckLoad: IGetTruckLoad },
    {
      where: {
        truck_load_id: string;
      };
    }
  > => gql`
    query FindTruckLoad($where: FindTruckLoadInput!) {
      getTruckLoad: FindTruckLoad(where: $where) {
        id
        pre_schedule_id
        status
        name
        noShippingCompany: no_shipping_company
        shippingCompany: shipping_company {
          id
          referenceId: reference_id
          companyName: company_name
          documentNumberCnpj: document_number_cnpj
          tradeName: trade_name
          email
          phoneNumber: phone_number
        }
        pick_up_location {
          title
          id
        }
        truck_load_items {
          id
          load_sort
          allocated_amount
          loaded_amount
          customer_id
          contract_item {
            id
            seed_type_id
            contract_id
            sieve
            category
            packing
            tsi
            estimated_weight
            cultivation
            reference_number
            contract {
              contractDate: contract_date
              referenceNumber: reference_number
              farm {
                name
                stateRegistration: state_registration
                customer {
                  name
                  tradeName: trade_name
                }
              }
            }
          }
        }
        estimated_weight
        vehicle {
          id
          vehicleModel: vehicle_model
          bagsCapacity: bags_capacity
          weightCapacity: weight_capacity
        }
        cadence {
          start_time
          rule {
            capacityPerCompany: capacity_per_company
            unity
            unity_weight
            seed_type {
              name
              name_without_accent
            }
          }
        }
        load_note
        loading_order {
          id
        }
        driver {
          name
          cpf: document_number_cpf
          vehicleId: vehicle_id
          vehicleModel: vehicle_model
          licensePlate: vehicle_license_plate
          phoneNumber: phone_number
          email
          note
          shippingCompanyId: shipping_company_id
        }
        authorization {
          name: user_name
          cpf: user_cpf
          phone: user_phone
          email: user_email
          date: authorized_at
        }
        customerManager: customer_manager {
          id
        }
        userManager: user_manager {
          id
        }
        cancellationReasons: cancellation_reason
      }
    }
  `,

  updateTruckLoad: (): TypedDocumentNode<
    {
      updateTruckLoad: { id: string };
    },
    {
      input: IUpdateTruckLoadInput;
      where: { truck_load_id: string };
    }
  > => gql`
    mutation updateTruckLoad(
      $input: TruckLoadUpdateInput!
      $where: TruckLoadUpdateWhere!
    ) {
      updateTruckLoad: UpdateTruckLoad(input: $input, where: $where) {
        id
      }
    }
  `,

  updateTruckLoadWithTruckLoadItems: (): TypedDocumentNode<
    {
      updateTruckLoadWithTruckLoadItems: { success: boolean };
    },
    {
      input: IUpdateTruckLoadWithTruckLoadItemsInput;
      where: { truck_load_id: string };
    }
  > => gql`
    mutation updateTruckLoadWithTruckLoadItems(
      $input: UpdateTruckLoadWithTruckLoadItemsInput!
      $where: UpdateTruckLoadWithTruckLoadItemsWhere!
    ) {
      updateTruckLoadWithTruckLoadItems: UpdateTruckLoadWithTruckLoadItems(
        input: $input
        where: $where
      ) {
        id
        referenceNumber: reference_number
        cadenceId: cadence_id
      }
    }
  `,

  getCadenceByPreScheduleID: (): TypedDocumentNode<
    {
      availableDates: string[];
    },
    {
      where: { pre_schedule_id: string };
    }
  > => gql`
    query GetPreScheduleCompatibleDates(
      $where: GetPreScheduleCompatibleDatesWhere!
    ) {
      availableDates: GetPreScheduleCompatibleDates(where: $where)
    }
  `,

  authorizeTruckLoad: (): TypedDocumentNode<
    {
      authorizeTruckLoad: { id: string; authorizedAt: string };
    },
    {
      input: { truck_load_id: string };
    }
  > => gql`
    mutation AuthorizeTruckLoad($input: LoadAuthorizationInput!) {
      AuthorizeTruckLoad(input: $input) {
        id
        authorizedAt: authorized_at
      }
    }
  `,

  synchronizeTruckLoad: (): TypedDocumentNode<
    {
      synchronizeTruckLoad: { success: boolean };
    },
    {
      input: { truck_load_id: string };
    }
  > => gql`
    mutation SynchronizeTruckLoad($input: LoadSynchronizationInput!) {
      synchronizeTruckLoad: SynchronizeTruckLoad(input: $input) {
        success
      }
    }
  `,

  cancelTruckLoad: (): TypedDocumentNode<
    {
      cancelTruckLoad: { success: boolean };
    },
    {
      where: {
        truck_load_id: string;
      };
    }
  > => gql`
    mutation CancelTruckLoad($where: FindTruckLoadInput!) {
      cancelTruckLoad: CancelTruckLoad(where: $where) {
        success
      }
    }
  `,

  loadingOrderPdfDownlod: (): TypedDocumentNode<
    { pdfDownload: { pdfUrl: string } },
    { where: { truck_load_id: string } }
  > => gql`
    query downloadPdf($where: GetLoadingOrderPdfWhere!) {
      pdfDownload: GetLoadingOrderPdf(where: $where) {
        pdfUrl: pdf_url
      }
    }
  `,

  getPreScheduleByCreator: (): TypedDocumentNode<
    {
      getPreScheduleByCreator: Array<{ id: string; created_by: string }>;
    },
    {
      where: { creator_id: string };
    }
  > => gql`
    query getPreSchedule($where: GetPreScheduleByCreatorInput!) {
      getPreScheduleByCreator: GetPreScheduleByCreator(where: $where) {
        id
        createdBy: created_by
      }
    }
  `,

  filterShippingCompanies: (): TypedDocumentNode<
    { shippingCompanies: IFilterShippingCompanies[] },
    {
      where: { document_number_cnpj: string };
    }
  > => gql`
    query listShippingCompanies($where: ListShippingCompanyWhereInput!) {
      shippingCompanies: ListShippingCompanies(where: $where) {
        shippingCompanyId: id
        shippingCompanyEmail: email
        shippingCompanyTradeName: trade_name
        shippingCompanyName: company_name
        shippingCompanyPhoneNumber: phone_number
        shippingCompanyDocumentNumberCnpj: document_number_cnpj
      }
    }
  `,

  findShippingCompany: (): TypedDocumentNode<
    { shippingCompanies: IFilterShippingCompanies[] },
    {
      where: { shipping_company_id: string };
    }
  > => gql`
    query listShippingCompanies($where: ListShippingCompanyWhereInput!) {
      shippingCompanies: ListShippingCompanies(where: $where) {
        shippingCompanyId: id
        shippingCompanyEmail: email
        shippingCompanyTradeName: trade_name
        shippingCompanyName: company_name
        shippingCompanyPhoneNumber: phone_number
        shippingCompanyDocumentNumberCnpj: document_number_cnpj
      }
    }
  `,

  findDriver: (): TypedDocumentNode<
    { driver: IDriverForm },
    { where: { document_number_cpf: string } }
  > => gql`
    query getDriver($where: GetDriverWhereInput!) {
      driver: GetDriver(where: $where) {
        id
        driver: name
        driverDocument: document_number_cpf
        vehicle: vehicle_model
        vehicleId: vehicle_id
        licensePlate: vehicle_license_plate
        phoneNumber: phone_number
        email
        driverObservation: note
      }
    }
  `,
};

export default TruckLoadQueries;
