import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Descriptions,
  message,
  Modal,
  Tag,
  Tooltip,
  Table,
  Row,
  Col,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import {
  IContractItems,
  IGetContract,
} from '../../structures/interfaces/Contract';
import { ScheduleStatus } from './StatusTags';

interface IViewContractsProps {
  open: boolean;
  onClose: () => void;
  contractData?: IGetContract;
}

const renderChildren = (data?: string | JSX.Element) => {
  if (data) return data;
  else return '---';
};

const ViewContracts = ({
  onClose,
  open,
  contractData,
}: IViewContractsProps) => {
  const { t } = useTranslation();
  const { customerData } = useContext(UserContext);

  const [items, setItems] = useState<IContractItems[]>([]);

  const [fetchItems, isLoading] = useRequest(
    ContractController.getContractItems
  );

  const fullAmountMessage = t('pages.contracts.fullAmountMessage');
  const remainingAmountMessage = t('pages.contracts.remainingAmountMessage');

  const tableColumns: ColumnsType<IContractItems> = [
    {
      title: t('pages.contracts.seedType'),
      dataIndex: ['seedType', 'name'],
      key: 'seedTypeName',
    },
    {
      title: t('pages.contracts.itemID'),
      dataIndex: ['referenceNumber'],
      key: 'itemID',
      render: contract => <Tag children={contract} />,
    },
    {
      title: t('pages.contracts.cultivate'),
      dataIndex: ['cultivation'],
      key: 'cultivation',
    },
    {
      title: t('pages.contracts.sieve'),
      dataIndex: ['sieve'],
      key: 'sieve',
    },
    {
      title: t('pages.contracts.category'),
      dataIndex: ['category'],
      key: 'category',
    },
    {
      title: t('pages.contracts.packing'),
      dataIndex: ['packing'],
      key: 'packing',
    },
    {
      title: t('pages.contracts.tsi'),
      dataIndex: ['tsi'],
      key: 'tsi',
      render: tsi =>
        tsi ? (
          <Tag color="blue" children={t('pages.contracts.yesTsi')} />
        ) : (
          <Tag color="volcano" children={t('pages.contracts.noTsi')} />
        ),
    },
    {
      title: t('pages.contracts.estimatedWeight'),
      dataIndex: ['estimatedWeight'],
      key: 'estimatedWeight',
    },
    {
      title: () => (
        <>
          {t('pages.contracts.remainingAmount')}{' '}
          <Tooltip title={remainingAmountMessage}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: ['remainingAmount'],
      key: 'remainingAmount',
    },
    {
      title: () => (
        <>
          {t('pages.contracts.fullAmount')}{' '}
          <Tooltip title={fullAmountMessage}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: ['fullAmount'],
      key: 'fullAmount',
    },
  ];

  const filteredColumns = tableColumns.filter(col => {
    if (customerData) {
      return (
        col.key !== 'category' &&
        col.key !== 'sieve' &&
        col.key !== 'tsi' &&
        col.key !== 'estimatedWeight' &&
        col.key !== 'itemID'
      );
    } else {
      return (
        col.key !== 'tsi' &&
        col.key !== 'estimatedWeight' &&
        col.key !== 'itemID'
      );
    }
  });

  const descriptions = (
    <Descriptions column={4} labelStyle={{ fontWeight: 'bold' }}>
      <Descriptions.Item label={t('pages.contracts.clients')}>
        {renderChildren(contractData?.farm.name)}
      </Descriptions.Item>

      <Descriptions.Item label={t('pages.contracts.stateRegistration')}>
        {renderChildren(contractData?.farm.stateRegistration)}
      </Descriptions.Item>

      <Descriptions.Item label={t('pages.contracts.contracts')}>
        {renderChildren(contractData?.referenceNumber)}
      </Descriptions.Item>

      <Descriptions.Item label={t('pages.contracts.status')}>
        {renderChildren(ScheduleStatus({ status: contractData?.status, t }))}
      </Descriptions.Item>

      <Descriptions.Item label={t('pages.contracts.date')}>
        {renderChildren(contractData?.contractDateString)}
      </Descriptions.Item>

      <Descriptions.Item label={t('pages.contracts.email')}>
        {renderChildren(contractData?.farm.email)}
      </Descriptions.Item>

      <Descriptions.Item label={t('pages.contracts.phoneNumber')}>
        {renderChildren(contractData?.farm.customer.phoneNumber)}
      </Descriptions.Item>

      <Descriptions.Item label={t('pages.contracts.salesmanName')}>
        {renderChildren(contractData?.salesman?.name)}
      </Descriptions.Item>

      <Descriptions.Item label={t('pages.contracts.salesmanDocumentNumberCpf')}>
        {renderChildren(contractData?.salesman?.documentNumberCpfMask)}
      </Descriptions.Item>
    </Descriptions>
  );

  const tableTitle = (
    <Typography.Title level={5}>
      {t('pages.contracts.listingCardTitle')}
    </Typography.Title>
  );

  useEffect(() => {
    if (contractData) {
      fetchItems(contractData.id)
        .then(setItems)
        .catch(() =>
          message.error('Não foi possível buscar os itens de seu contrato')
        );
    }
  }, [contractData]);

  return (
    <Modal
      width={'70%'}
      open={open}
      onCancel={onClose}
      footer={false}
      style={{ zIndex: 1 }}
      title={t('pages.contracts.contracts')}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>{descriptions}</Col>

        <Col span={24}>
          <Table
            title={() => tableTitle}
            columns={filteredColumns}
            dataSource={items}
            loading={isLoading}
            rowKey={item => item.id}
            size="small"
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewContracts;
