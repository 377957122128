import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, Table, Tag } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { IGetContract } from '../../structures/interfaces/Contract';

const renderStatus = (status: string) => {
  switch (status) {
    case 'WAITING_RELEASE':
      return <Tag color="blue" children={'AGUARDANDO LIBERAÇÃO'} />;
    case 'WAITING_SCHEDULE':
      return <Tag color="orange" children={'AGUARDANDO AGENDAMENTO'} />;
    case 'SCHEDULE_FINISHED':
      return <Tag color="green" children={'FINALIZADO'} />;
  }
};

interface IContractsTableProps extends TableProps<IGetContract> {
  setViewContracts: (v: boolean) => void;
  setContractToView: (v: IGetContract) => void;
}

const ContractsTable = ({
  setContractToView,
  setViewContracts,
  ...props
}: IContractsTableProps) => {
  const { t } = useTranslation();

  const columnsContract: ColumnsType<IGetContract> = [
    {
      title: 'Fazenda',
      dataIndex: ['farm', 'name'],
      key: 'farmName',
    },
    {
      title: t('pages.home.stateRegistration'),
      dataIndex: ['farm', 'stateRegistration'],
      key: 'stateRegistration',
      render: sr => <Tag children={sr} />,
    },
    {
      title: t('pages.home.contract'),
      dataIndex: ['referenceNumber'],
      key: 'referenceNumber',
      render: contract => <Tag children={contract} />,
    },
    {
      title: 'Status',
      dataIndex: ['status'],
      key: 'status',
      width: 'auto',
      render: status => renderStatus(status),
    },
    {
      title: t('pages.home.contractDate'),
      dataIndex: ['contractDateString'],
      key: 'date',
    },
    {
      title: t('pages.contracts.itemID'),
      dataIndex: ['contract_item_reference_number'],
      key: 'itens',
      render: itemId => <Tag>{itemId}</Tag>,
    },
    {
      title: t('pages.contracts.estimatedWeight'),
      dataIndex: ['contract_item_estimated_weight'],
      key: 'estimatedWeight',
      render: weight => {
        if (weight > 1000) {
          return `${weight / 1000} ton`;
        } else {
          return `${weight} kg`;
        }
      },
    },
    {
      title: t('pages.contracts.tsi'),
      dataIndex: ['contract_item_tsi'],
      key: 'tsi',
      render: tsi => {
        return tsi ? (
          <Tag color="blue">{t('pages.contracts.yesTsi')}</Tag>
        ) : (
          <Tag color="volcano">{t('pages.contracts.noTsi')}</Tag>
        );
      },
    },
    {
      title: t('pages.contracts.actions'),
      key: 'action',
      render: (_, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          children={t('pages.contracts.view')}
          onClick={() => {
            setViewContracts(true);
            setContractToView(record);
          }}
        />
      ),
    },
  ];

  const filteredColumns = columnsContract.filter(
    item =>
      item.key !== 'estimatedWeight' &&
      item.key !== 'tsi' &&
      item.key !== 'itens'
  );

  return (
    <Card title="Itens de contrato">
      <Table {...props} size="small" columns={filteredColumns} />
    </Card>
  );
};

export default ContractsTable;
